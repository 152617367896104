import React, { Component } from 'react';
class Button extends Component {
    buttonClickEvent = () => {
        this.props.buttonClick();
      
    }
    render() {
        return (
            <div
                onClick={this.buttonClickEvent}
                className={this.props.btnClasses + (this.props.invertColor ? " invert-color" : "") + (this.props.disabled ? " disabled" : "")}>
                {this.props.buttonText}
            </div>
        );
    }
}
export default Button;