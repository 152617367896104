export const ApplyCoupon = () =>{

    return(
        <svg id="notifications_24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_92296" data-name="Group 92296">
                <rect id="Boundary" width="24" height="24" fill="none"/>
                <path id="Union_234" data-name="Union 234" d="M6.792,19.321.679,13.208a2.318,2.318,0,0,1,0-3.279L9.328,1.28a4.073,4.073,0,0,1,2.833-1.2L17.682,0h0A2.319,2.319,0,0,1,20,2.353l-.082,5.486a4.071,4.071,0,0,1-1.2,2.833l-8.649,8.649a2.318,2.318,0,0,1-3.279,0ZM1.308,10.558a1.429,1.429,0,0,0,0,2.022L7.42,18.693a1.43,1.43,0,0,0,2.022,0l1.052-1.052L4.649,11.8a.444.444,0,0,1,.628-.628l5.845,5.845,6.969-6.969a3.187,3.187,0,0,0,.938-2.217l.082-5.487A1.43,1.43,0,0,0,17.682.889L12.174.971a3.188,3.188,0,0,0-2.218.938L2.988,8.878,4.11,10a.444.444,0,1,1-.628.628L2.359,9.507ZM13.251,6.749a2.185,2.185,0,0,1-.585-2.059.444.444,0,0,1,.865.2,1.308,1.308,0,1,0,.754-.9.444.444,0,0,1-.352-.816h0a2.2,2.2,0,1,1-.682,3.571Z" transform="translate(2 2)" fill="#20262e"/>
            </g>
        </svg>

    )
}

export const RightOpenArrow = ()=>{
    return(
        <svg id="Component_193_126" data-name="Component 193 – 126" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Rectangle_298" data-name="Rectangle 298" width="24" height="24" rx="10" fill="#20262e" opacity="0.1"/>
            <path id="Path_108788" data-name="Path 108788" d="M2.978,5.639.231,8.373a.783.783,0,0,0,1.1,1.11L5.428,5.411h0a.784.784,0,0,0,0-1.109h0L1.336.228a.783.783,0,0,0-1.1,1.11L2.978,4.073l.78.776Z" transform="translate(9.041 7.083)" fill="#202020"/>
        </svg>
    )
}

export const LeftOpenArrow =()=>{
    return (
        <svg id="Component_193_79" data-name="Component 193 – 79" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <rect id="Rectangle_298" data-name="Rectangle 298" width="28" height="28" rx="10" fill="#20262e" opacity="0.1" />
            <path id="Path_108788" data-name="Path 108788" d="M3.019,6.351l3.095,3.08a.882.882,0,0,1-1.245,1.25L.26,6.094h0a.883.883,0,0,1,0-1.249h0L4.869.257a.882.882,0,0,1,1.245,1.25L3.019,4.587l-.878.874Z" transform="translate(9.963 8.64)" fill="#20262e" />
        </svg>
    )
}

export const CloseOffeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.757" height="20.752" viewBox="0 0 20.757 20.752">
            <g id="close_7_" data-name="close (7)" transform="translate(0.8 0.8)">
                <g id="Group_32" data-name="Group 32" transform="translate(0 0)">
                    <g id="Group_31" data-name="Group 31">
                        <path id="Path_35" data-name="Path 35" d="M16.356,2.871a9.576,9.576,0,1,0,0,13.549A9.593,9.593,0,0,0,16.356,2.871Z" transform="translate(0 -0.07)" fill="none" stroke="#000" stroke-width="1.6" />
                    </g>
                </g>
                <g id="Group_34" data-name="Group 34" transform="translate(5.538 5.446)">
                    <g id="Group_33" data-name="Group 33">
                        <path id="Path_36" data-name="Path 36" d="M175.151,171.443,172.6,168.9l2.55-2.546a.9.9,0,1,0-1.273-1.274l-2.552,2.548-2.552-2.548a.9.9,0,1,0-1.273,1.274l2.55,2.546-2.55,2.546a.9.9,0,0,0,1.274,1.274l2.552-2.548,2.552,2.548a.9.9,0,1,0,1.273-1.274Z" transform="translate(-167.236 -164.813)" />
                    </g>
                </g>
            </g>
        </svg>
    )
}


export const GritzoLogo = `<svg
version="1.1"
id="svg2"
width="119"
height="30"
viewBox="0 0 480 118.51827"
sodipodi:docname="Gritzo_R -Logo.ai"
xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
xmlns="http://www.w3.org/2000/svg"
xmlns:svg="http://www.w3.org/2000/svg">
<defs
  id="defs6">
 <clipPath
    clipPathUnits="userSpaceOnUse"
    id="clipPath16">
   <path
      d="M 0,88.889 H 360 V 0 H 0 Z"
      id="path14" />
 </clipPath>
</defs>
<sodipodi:namedview
  id="namedview4"
  pagecolor="#ffffff"
  bordercolor="#000000"
  borderopacity="0.25"
  inkscape:showpageshadow="2"
  inkscape:pageopacity="0.0"
  inkscape:pagecheckerboard="0"
  inkscape:deskcolor="#d1d1d1" />
<g
  id="g8"
  inkscape:groupmode="layer"
  inkscape:label="Gritzo_R -Logo"
  transform="matrix(1.3333333,0,0,-1.3333333,0,118.51827)">
 <g
    id="g10">
   <g
      id="g12"
      clip-path="url(#clipPath16)">
     <g
        id="g18"
        transform="translate(39.7767,67.9208)">
       <path
          d="m 0,0 c -2.89,-1.293 -5.159,-2.659 -6.756,-4.048 -1.582,-1.382 -3.004,-3.013 -4.242,-4.841 -1.228,-1.81 -2.28,-3.814 -3.136,-5.972 -0.865,-2.157 -1.45,-4.546 -1.733,-7.079 l -0.317,-2.964 c -0.446,-4.035 -0.209,-7.852 0.708,-11.336 0.96,-3.577 2.564,-6.381 4.795,-8.326 2.189,-1.906 4.036,-3.091 5.621,-3.657 1.468,-0.511 2.641,-0.874 3.469,-1.083 0.889,-0.222 1.69,-0.367 2.404,-0.434 0.634,-0.071 1.412,-0.16 2.496,-0.305 l 29.407,0.129 3.534,31.885 -28.915,-0.129 -1.25,-11.3 14.919,0.068 -1.003,-9.021 -11.472,-0.044 c -4.441,-0.101 -7.215,1.105 -8.412,3.346 -1.333,2.457 -1.751,5.999 -1.243,10.539 l 0.086,0.757 c 0.311,2.801 0.948,5.369 1.905,7.637 0.911,2.158 2.271,3.894 4.057,5.149 1.748,1.247 4.152,1.884 7.144,1.903 L 37.25,-9.016 38.481,2.139 13.008,2.031 C 7.233,2.004 2.986,1.342 0,0"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path20" />
     </g>
     <g
        id="g22"
        transform="translate(118.2807,54.0385)">
       <path
          d="M 0,0 C -0.166,-1.524 -0.643,-2.681 -1.348,-3.367 -2.318,-4.18 -3.395,-4.7 -4.537,-4.897 -5.83,-5.116 -7.483,-5.236 -9.456,-5.248 l -12.306,-0.046 1.106,9.972 12.601,0.059 C -5.014,4.792 -2.81,4.423 -1.314,3.78 -0.85,3.583 0.342,3.056 0,0 m 10.262,12.112 c -1.598,1.616 -3.41,2.653 -5.411,3.078 -1.822,0.391 -3.494,0.662 -4.965,0.8 -1.459,0.132 -2.896,0.176 -4.377,0.194 l -28.665,-0.126 -5.768,-52.061 13.774,0.061 2.136,19.345 11.216,0.053 9.656,-19.296 15.43,0.067 -11.069,21.106 c 2.025,0.613 3.771,1.462 5.211,2.555 1.807,1.37 3.3,3.13 4.457,5.245 1.019,2.013 1.668,4.291 1.942,6.772 0.256,2.234 0.154,4.453 -0.307,6.679 -0.594,2.08 -1.69,3.94 -3.26,5.528"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path24" />
     </g>
     <g
        id="g26"
        transform="translate(135.0172,18.2809)">
       <path
          d="M 0,0 14.294,0.071 20.059,52.12 5.765,52.061 Z"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path28" />
     </g>
     <g
        id="g30"
        transform="translate(206.7307,70.4941)">
       <path
          d="m 0,0 -48.521,-0.212 -1.225,-11.155 17.064,0.077 -4.516,-40.771 14.221,0.07 4.515,40.759 17.222,0.077 z"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path32" />
     </g>
     <g
        id="g34"
        transform="translate(207.1368,59.2098)">
       <path
          d="m 0,0 28.635,0.12 -31.959,-30.112 -1.179,-10.638 47.472,0.219 1.24,11.293 -28.068,-0.126 31.402,30.121 1.194,10.761 -47.469,-0.209 z"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path36" />
     </g>
     <g
        id="g38"
        transform="translate(298.9656,45.0374)">
       <path
          d="m 0,0 c -0.283,-2.481 -0.874,-4.866 -1.791,-7.163 -1.438,-4.222 -4.1,-6.913 -8.086,-8.215 -1.721,-0.477 -3.534,-0.754 -5.331,-0.729 -1.838,-0.013 -4.088,0.434 -6.692,1.308 -2.299,0.773 -3.65,2.632 -4.149,5.697 -0.557,3.42 -0.686,6.409 -0.419,8.899 0.28,2.533 0.825,4.869 1.619,6.941 1.958,4.404 4.704,7.122 8.317,8.292 1.711,0.489 3.46,0.732 5.174,0.742 0.988,-0.019 1.816,-0.062 2.672,-0.179 0.889,-0.138 1.865,-0.357 2.896,-0.665 0.942,-0.289 1.816,-0.809 2.604,-1.536 0.822,-0.748 1.438,-1.529 1.841,-2.32 0.52,-1.078 0.717,-1.607 0.785,-1.86 C -0.443,8.775 -0.255,7.75 0.028,6.134 0.289,4.586 0.283,2.533 0,0 m 9.486,20.653 c -1.065,1.21 -2.348,2.213 -3.807,2.986 -1.407,0.745 -2.502,1.24 -3.331,1.487 -0.757,0.237 -2.16,0.594 -4.201,1.077 -2.087,0.492 -4.875,0.73 -8.511,0.714 -1.89,-0.052 -3.718,-0.086 -5.251,-0.228 -1.496,-0.154 -3.228,-0.461 -5.121,-0.911 -1.915,-0.455 -3.805,-1.117 -5.618,-1.97 -1.81,-0.858 -3.844,-2.207 -6.039,-4.016 -2.262,-1.872 -4.232,-4.753 -5.845,-8.56 -1.502,-3.5 -2.499,-7.255 -2.927,-11.183 -0.453,-3.998 -0.397,-7.439 0.145,-10.219 0.557,-2.804 1.206,-4.946 2.016,-6.565 0.809,-1.653 1.871,-3.106 3.164,-4.309 1.259,-1.201 2.564,-2.152 3.887,-2.838 1.293,-0.671 2.349,-1.154 3.238,-1.475 0.881,-0.307 1.856,-0.566 2.928,-0.751 3.047,-0.68 6.3,-1.031 9.658,-1.018 h 0.096 c 3.367,0.015 6.95,0.434 10.628,1.249 3.774,0.831 7.206,2.453 10.197,4.823 3.002,2.389 5.344,5.566 6.969,9.447 1.591,3.801 2.61,7.658 3.035,11.481 0.419,3.881 0.255,7.732 -0.502,11.447 -0.788,3.832 -2.431,6.993 -4.808,9.332"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path40" />
     </g>
     <g
        id="g42"
        transform="translate(320.6846,72.1735)">
       <path
          d="m 0,0 c -2.715,0 -4.924,-2.209 -4.924,-4.924 0,-2.716 2.209,-4.925 4.924,-4.925 2.715,0 4.924,2.209 4.924,4.925 C 4.924,-2.209 2.715,0 0,0 m 0,-10.541 c -3.097,0 -5.617,2.52 -5.617,5.617 0,3.097 2.52,5.616 5.617,5.616 3.097,0 5.617,-2.519 5.617,-5.616 0,-3.097 -2.52,-5.617 -5.617,-5.617"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path44" />
     </g>
     <g
        id="g46"
        transform="translate(319.7005,67.3955)">
       <path
          d="m 0,0 h 1.056 c 0.347,0 0.618,0.087 0.813,0.262 0.195,0.175 0.293,0.414 0.293,0.717 0,0.317 -0.09,0.562 -0.271,0.736 C 1.711,1.888 1.442,1.977 1.085,1.983 H 0 Z M 1.146,-0.829 H 0 v -2.275 h -1.028 v 5.916 h 2.08 c 0.683,0 1.21,-0.153 1.581,-0.46 C 3.004,2.046 3.19,1.603 3.19,1.022 3.19,0.627 3.094,0.295 2.903,0.028 2.712,-0.239 2.446,-0.444 2.105,-0.588 L 3.433,-3.052 V -3.104 H 2.332 Z"
          style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path48" />
     </g>
   </g>
 </g>
</g>
</svg>`