import React, { Component } from 'react';
import HK from 'factory/modules/HK';
import Link from 'components/Assets/LinkHref';
import { connect } from 'react-redux';
/*to use this component, props required are:
isLink: true/false
isFullUrl: true/false
childOf: name of parent container
nam/id: name of link clicked/ id of link clicked
url: link / object containing urlFragment and navKey of the link
ind: position of link
 */
class Itrack extends Component {
	createBaseUrl(ele) {
		const w1 = this.props.pageTypeChange || (typeof window != 'undefined' ? window.pageType : this.props.pageType),
			p = ele.ind,
			c = ele.nam ? ele.nam.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase() : null,
			e = ele.id;
		let w2 = '';
		if (ele.childOf) {
			w2 = ele.childOf;
			if (ele.childOf.indexOf('undefined') > -1) {
				w2 = (ele.childOf).split('|undefined')[0];
			}
			w2 = w2.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase();
		}
		if (w2 === 'undefined') {
			w2 = '';
		}
		if (w1 === "search") {
			let searchTerm;
			if(typeof window != 'undefined') {
				searchTerm = document.getElementsByClassName('hk-search-box')[0] && document.getElementsByClassName('hk-search-box')[0].value || this.props._searchState
			} else {
				searchTerm = this.props._searchState
			}
			w2 += '|searchedTerm-' + searchTerm;
		}
		if (ele.url === null) { return ""; }
		else {
			if (ele.isFullUrl) {
				var itrackStr = HK.itracker([w1, w2], p, c, e) + (ele.ac_sel ? '&' + ele.ac_sel : '');
				return ele.url ? (ele.url.indexOf('?') < 0 ? ele.url + "?" + itrackStr : ele.url + "&" + itrackStr) : ''
			}
			else {
				return (HK.urlMaker(ele.url.urlFragment ? ele.url.urlFragment : '', ele.url.navKey ? ele.url.navKey : '', [w1 + "|" + w2, p, c, e]));
			}
		}
	}
	shouldComponentUpdate(nextProps) {
		if (nextProps !== this.props)
			return true;
		else
			return false;
	}
	render() {
		if (this.props.isLink && !this.props.nextJSReload) {
			return (
				<Link key={this.props.key||''} itemProp ="url" to={this.createBaseUrl(this.props)} title={this.props.title} target={this.props.target} rel={this.props.rel} className={this.props.classname} onClick={this.props.onClick}>
					{this.props.children}
				</Link>
				
			)
		}
		else {
			return (
				<a key={this.props.key||''} itemProp ="url"  style={this.props.style} title={this.props.title} href={this.createBaseUrl(this.props)} target={this.props.target} rel={this.props.rel} className={this.props.classname} onClick={this.props.onClick}>
					{this.props.children}
				</a>
			)
		}
	}
}
// Take value from store
function mapStateToProps(state) {
    return {
        pageType: state.pageTypeReducer,
		_searchState: state.searchStateReducer
    };
}
export default connect(mapStateToProps, null)(Itrack);