import React, { Component } from 'react';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'Atom/Button.js'
import HK from 'factory/modules/HK';
import { headerMenuAction } from 'components/common/header/action';
import { isLoginPopupAction } from 'components/common/login/action';
import IntersectionObserver from '../../../components/common/IntersectionObserver'
import { CHECKOUT_ANALYTICS } from '../../../factory/modules/DatalayerService';
import styles from '../../../components/Cart/cart.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
import PaymentPendingOrderConfirmationPopup from '../../../components/OrderSuccess/PaymentPending/common/paymentPendingOrder';

class ProceedToCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProceedToCheckout: false,
            currentpath: null,
            isPendingOrderExists: false,
            confirmationOnPendingOrder: false
        }
        const popupType = 'checkout'
    }

    componentDidMount = () => {
        this.setState({ currentpath: window.location.pathname.split('/')[1] })
        this.popupType = window.location.pathname.split('/')[1]
    }

    moveToCheckout = () => {
        if ((this.state.isPendingOrderExists && this.state.confirmationOnPendingOrder) || !this.state.isPendingOrderExists) {
            this.handleProceed();
        }
    }

    componentDidUpdate = () => {
        if (this.state.confirmationOnPendingOrder) {
            this.moveToCheckout()
        }
    }

    confirmationByUserForPendingPayment = (action) => {
        if (action === 'yes') {
            this.setState({
                isPendingOrderExists: false,
                confirmationOnPendingOrder: true
            })
        } else {
            this.setState({
                isPendingOrderExists: false,
                confirmationOnPendingOrder: false
            })
        }
    };

    handleProceed = () => {
        if (this.props.cartUrl) {
            // this.props.history.push('/cart/Cart.action');
            window.location.href = '/cart/Cart.action';
        } else {
            CHECKOUT_ANALYTICS(this.props._cartOrderSummaryReducer, 1);
            // this.props.history.push('/checkout');
            window.location.href = '/checkout';
        }
    }

    handleScrollToBottom = () => {
        // document.getElementById("order-summery-container").scrollIntoView(false);
        HK.scrollIntoView("order-summery-container")
    }

    proceedToCheckout = () => {
        if (this.props.handleReviewOrder) {
            this.props.handleReviewOrder()
        } else {
            const eventData = {
                eventName: "CHECKOUT_CLICK",
                widgetName: "Checkout"
            }
            HK.triggerComponentClickEvent(eventData)
            let redirectTo = '/checkout';
            !HK.isMobile() && this.props._headerMenuAction(null);
            if (this.props._isLogedInReducer) {
                if (this.props._cartHeaderSummaryReducer && this.props._cartHeaderSummaryReducer.cartSummary && this.props._cartHeaderSummaryReducer.cartSummary.isPendingShoppingCartActive) {
                    this.setState({
                        isPendingOrderExists: true
                    })
                } else {
                    this.moveToCheckout()
                }
            } else {
                this.setState({
                    isProceedToCheckout: true
                })
                this.props._isLoginPopupAction({ isShow: true, activeTab: "login", redirectTo: redirectTo });
            }
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps._isLogedInReducer && this.state.isProceedToCheckout) {
            this.handleProceed()
        }
    }

    totalPayable = () => {
        let totPay = this.props._cartOrderSummaryReducer && this.props._cartOrderSummaryReducer.totPay
        if (this.props._cardOfferAvldReducer && this.props._cardOfferAvldReducer.paymentMode === "INSTANT" && this.props._cardOfferAvldReducer.cardDiscount && this.props._cardOfferAvldReducer.cardDiscount > 0) {
            totPay = totPay - this.props._cardOfferAvldReducer.cardDiscount;
            if (totPay < 0) {
                totPay = 0;
            }
        }
        return totPay ? totPay.toLocaleString() : '0'
    }

    render() {
        return (
            <IntersectionObserver className={convertToModule(styles, "width-100")} onClick={this.props.onClick} onChange={this.props.onChange} >
                {
                    HK.isMobile() ?
                        <div className={convertToModule(styles, 'payment-button-action-title-section')} >
                            <span className={convertToModule(styles, 'payment-button-action-payable-amt')}>
                                &#x20b9; {this.totalPayable()}
                            </span>
                            <span onClick={() => this.handleScrollToBottom()} className={convertToModule(styles, 'payment-button-action-summery-btn')}>
                                View Order Summary
                            </span>
                        </div>
                        : ''
                }
                <Button data-event="CheckoutStarted" type="button" buttonClick={this.proceedToCheckout.bind(this)} btnClasses={convertToModule(styles, (this.props.btnClassName) + ' btn checkout-btn')} buttonText={this.props.displayName}></Button>
                {
                    this.state.isPendingOrderExists ?
                        <PaymentPendingOrderConfirmationPopup confirmationByUserForPendingPayment={this.confirmationByUserForPendingPayment} /> : <></>
                }
            </IntersectionObserver>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _isLoginPopupAction: isLoginPopupAction,
        _headerMenuAction: headerMenuAction,
    }, dispatch)
}

function mapStateToProps(state) {
    return {
        _cartOrderSummaryReducer: state.cartOrderSummaryReducer,
        _isLogedInReducer: state.isLogedInReducer,
        _cartHeaderSummaryReducer: state.cartHeaderSummaryReducer,
        _pageType: state.pageTypeReducer,
        _cartVariantReducer: state.cartVariantReducer,
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProceedToCheckout)
);