import Analytics from '../../../factory/modules/Analytics';
import HK from '../../../factory/modules/HK';

let scroll_quarterDone = false;
let scroll_halfDone = false;
let scroll_halfAndAQuarterDone = false;
let scroll_fullDone = false;
let image_viewed_array = [];
let about_prod_array = [];
let notify_me_flag = false;
let view_offers_flag = false;
let scrollPositionTemp = 0;

var PDPEventTracker = {
    yScroll: function (data, docSecsPositions) {
        //let scrollPos = window.scrollY;
        //   console.log("SCROLLING... ", scrollPos);
        if (docSecsPositions.offer_section < 0 && !scroll_quarterDone) {
            scroll_quarterDone = true;
            scrollPositionTemp = scrollPositionTemp + 1;
            let eventData = {};
            eventData.scrollPosition = scrollPositionTemp;
            this.track(HK.config.eventNames.PRODUCT_PAGE_SCROLL, eventData, data);
        }
        if (docSecsPositions.about_section < 0 && !scroll_halfDone) {
            scroll_halfDone = true;
            scrollPositionTemp = scrollPositionTemp + 1;
            let eventData = {};
            eventData.scrollPosition = scrollPositionTemp;
            this.track(HK.config.eventNames.PRODUCT_PAGE_SCROLL, eventData, data);
        }
        if (docSecsPositions.rating_section < 0 && !scroll_halfAndAQuarterDone) {
            scroll_halfAndAQuarterDone = true;
            scrollPositionTemp = scrollPositionTemp + 1;
            let eventData = {};
            eventData.scrollPosition = scrollPositionTemp;
            this.track(HK.config.eventNames.PRODUCT_PAGE_SCROLL, eventData, data);
        }
        if (docSecsPositions.bottom_section < 0 && !scroll_fullDone) {
            scroll_fullDone = true;
            scrollPositionTemp = scrollPositionTemp + 1;
            let eventData = {};
            eventData.scrollPosition = scrollPositionTemp;
            this.track(HK.config.eventNames.PRODUCT_PAGE_SCROLL, eventData, data);
        }
    },

    imageViewed: function (data, imageIndex, imageType) {
        let iva_key = imageType + imageIndex;
        if (image_viewed_array.indexOf(iva_key) === -1) {
            image_viewed_array.push(iva_key);
            let eventData = {};
            eventData.index = imageIndex;
            eventData.type = imageType;
            eventData.totalCount = data.images.length;
            this.track(HK.config.eventNames.PRODUCT_IMAGE_VIEWED, eventData, data);
        }
    },

    viewAllReviewsClick: function (data) {
        let eventData = {};
        eventData.count = data.userRev.length;
        eventData.totalCount = data.userRev.length;
        this.track(HK.config.eventNames.PRODUCT_REVIEW_READ, eventData, data);
    },

    aboutProductClick: function (data, sectionTitle) {
        if (about_prod_array.indexOf(sectionTitle) === -1) {
            about_prod_array.push(sectionTitle);
            let eventData = {};
            eventData.sectionTitle = sectionTitle;
            this.track(HK.config.eventNames.PRODUCT_ABOUT_TAB_CLICK, eventData, data);
        }
    },

    viewOffersClick: function (data) {
        if (!view_offers_flag) {
            view_offers_flag = true;
            let eventData = {};
            eventData.count = data.pgOff.length;
            this.track(HK.config.eventNames.PRODUCT_VIEW_OFFER, eventData, data);
        }
    },

    notifyMeClick: function (data) {
        if (!notify_me_flag) {
            notify_me_flag = true;
            let eventData = {};
            this.track(HK.config.eventNames.PRODUCT_NOTIFY_ME, eventData, data);
        }
    },

    wishListIconClick: function (data) {
        // temparory disabled
        // let eventData = {};
        // this.track(HK.config.eventNames.PRODUCT_WISHLIST, eventData, data);
    },

    screenViewPDP: function (data) {
        let eventData = {};
        // this.track(HK.config.eventNames.SCREEN_VIEW, eventData, data);
    },

    track: function (eventName, evntData, productdata) {
        
        if (productdata) {
            let eventData = evntData;
            if (window.pageType === "storeVariant") {
                eventData.variantId = productdata.id;
                eventData.itemName = evntData.Itemname?  productdata.nm : evntData.Itemname;
                // eventData.category = productdata.pgBreadCrumbs[1].nm;
                eventData.price = parseInt(productdata.offer_pr, 10);
                eventData.quantity = 1;
                eventData.brand = productdata.brName;
                eventData.primaryAttributeName = '';
                eventData.primaryAttributeValue = '';
                eventData.secondaryAttributeName = '';
                eventData.secondaryAttributeValue = '';
                for (let at = 0; at < productdata.attr.length; at++) {
                    if (at === 0) {
                        eventData.primaryAttributeName = productdata.attr[at].dis_nm;
                        eventData.primaryAttributeValue = productdata.selAttr[productdata.attr[at].nm];
                    }
                    if (at === 1) {
                        eventData.secondaryAttributeName = productdata.attr[at].dis_nm;
                        eventData.secondaryAttributeValue = productdata.selAttr[productdata.attr[at].nm];
                    }
                }
                eventData.url = window.location.href;
                eventData.vendorName = productdata.vendorName;
                eventData.catPrefix = productdata.catPre;
                // eventData.leafNode = productdata.pgBreadCrumbs[productdata.pgBreadCrumbs.length - 1].nm;
            }
            if (window.pageType === "pack") {
                eventData.packId = productdata.id;
                eventData.itemName = evntData.Itemname ? productdata.nm : evntData.Itemname;
                eventData.category = '';
                eventData.price = productdata.offer_pr;
                eventData.quantity = 1;
                eventData.brand = '';
                eventData.leafNode = '';
                eventData.primaryAttributeName = '';
                eventData.primaryAttributeValue = '';
                eventData.secondaryAttributeName = '';
                eventData.secondaryAttributeValue = '';
                eventData.url = window.location.href.split("?")[0]; // trimming url due to max length issue.
                eventData.vendorName = '';
                // eventData.productImageUrl = productdata.image.m_link;
                // eventData.catPrefix = productdata.catPre;
                // eventData.leafNode = productdata.pgBreadCrumbs[productdata.pgBreadCrumbs.length -1].nm;
            }
            Analytics.Scope().triggerClickStreamEvent(eventName, eventData);
        }
    },
    getData: function (evntData, productdata) {
        
        let eventData = {};
        if (productdata) {
            let eventData = evntData;
            if (window.pageType === "storeVariant") {
                eventData.variantId = productdata.id;
                eventData.itemName = !evntData.Itemname ? productdata.nm : evntData.Itemname;
                // eventData.category = productdata.pgBreadCrumbs[1].nm;
                eventData.price = parseInt(productdata.offer_pr, 10);
                eventData.quantity = 1;
                eventData.brand = productdata.brName;
                eventData.primaryAttributeName = '';
                eventData.primaryAttributeValue = '';
                eventData.secondaryAttributeName = '';
                eventData.secondaryAttributeValue = '';
                for (let at = 0; at < productdata.attr.length; at++) {
                    if (at === 0) {
                        eventData.primaryAttributeName = productdata.attr[at].dis_nm;
                        eventData.primaryAttributeValue = productdata.selAttr[productdata.attr[at].nm];
                    }
                    if (at === 1) {
                        eventData.secondaryAttributeName = productdata.attr[at].dis_nm;
                        eventData.secondaryAttributeValue = productdata.selAttr[productdata.attr[at].nm];
                    }
                }
                eventData.url = window.location.href;
                eventData.vendorName = productdata.vendorName;
                eventData.catPrefix = productdata.catPre;
                // eventData.leafNode = productdata.pgBreadCrumbs[productdata.pgBreadCrumbs.length - 1].nm;
            }
            if (window.pageType === "pack") {
                eventData.packId = productdata.id;
                eventData.itemName =evntData.Itemname ? productdata.nm : evntData.Itemname;
                eventData.category = '';
                eventData.price = productdata.offer_pr;
                eventData.quantity = 1;
                eventData.brand = '';
                eventData.leafNode = '';
                eventData.primaryAttributeName = '';
                eventData.primaryAttributeValue = '';
                eventData.secondaryAttributeName = '';
                eventData.secondaryAttributeValue = '';
                eventData.url = window.location.href.split("?")[0]; // trimming url due to max length issue.
                eventData.vendorName = '';
                // eventData.productImageUrl = productdata.image.m_link;
                // eventData.catPrefix = productdata.catPre;
                // eventData.leafNode = productdata.pgBreadCrumbs[productdata.pgBreadCrumbs.length -1].nm;
            }
            return eventData
        } else {
            return eventData
        }
    }
}
export default PDPEventTracker;