import HK from "./HK";

export const CHECKOUT_ANALYTICS = (cartReducer, step) => {
    let expressCheckout = HK.getSearchParmas().expressCheckout === "true";
    // let oos = cartReducer.hkUserLoyaltyServiceProductDto && cartReducer.hkUserLoyaltyServiceProductDto.membershipStoreVariant && cartReducer.hkUserLoyaltyServiceProductDto.membershipStoreVariant.oos;
    let products = []
    let items = [];
    let filter;
    if (HK.isMobile()) {
        filter = sessionStorage.getItem("mobilefilter")
    } else {
        filter = sessionStorage.getItem("filterClicked");
    }

    let btnFlow;
    let flowdata = localStorage.getItem('Flow')
    if (flowdata === "Normal Flow") {
        btnFlow = "Normal Flow"
    } else if (flowdata === "Addto Cart Flow") {
        btnFlow = "Addto Cart Flow"
    }
    else if (flowdata === "Buy Now Flow") {
        btnFlow = "Buy Now Flow"
    } else {
        btnFlow = flowdata;
    }

    let itemNames = [], ids = [], prices = [];
    let dataCart = {}, cartItemIdsArr = [], cartItemNameArr = [], cartItemPriceArray = [], cartItemMrpArr = [], cartItemQuantityArr = [], cartItemSecCatArr = [];

    if (cartReducer && cartReducer.cartPacks && cartReducer.cartPacks.length > 0) {
        cartReducer.cartPacks.forEach(item => {
            item.variants && item.variants.length > 0 && item.variants.forEach(vrn => {
                products.push(createProductDataLayerObj(vrn, btnFlow, filter))
                items.push(createItemsDataLayerObj(vrn, btnFlow, filter))
                let name = vrn.sv_nm
                let id = vrn.sv_id
                let price = vrn.totOffPr
                itemNames.push(name)
                ids.push(id)
                prices.push(price)
                cartItemIdsArr.push(vrn.sv_id)
                cartItemNameArr.push(vrn.sv_nm)
                cartItemPriceArray.push(vrn.totOffPr / vrn.qty)
                cartItemMrpArr.push(vrn.totMrp / vrn.qty)
                cartItemQuantityArr.push(vrn.qty)
                cartItemSecCatArr.push(vrn.secondary_category)
                if (cartItemIdsArr.length) { dataCart.cartItemIds = cartItemIdsArr.join(','); }
                if (cartItemNameArr.length) { dataCart.cartItemsNames = cartItemNameArr.join(','); }
                if (cartItemPriceArray.length) { dataCart.cartItemsPrice = cartItemPriceArray.join(','); }
                if (cartItemMrpArr.length) { dataCart.cartItemsMrp = cartItemMrpArr.join(','); }
                if (cartItemQuantityArr.length) { dataCart.cartItemsQuantity = cartItemQuantityArr.join(','); }
                if (cartItemSecCatArr.length) { dataCart.cartItemsSecondaryCatogories = cartItemSecCatArr.join(','); }
                if (cartItemPriceArray.length && cartItemQuantityArr.length && cartItemPriceArray.length === cartItemQuantityArr.length) {
                    let totalCartValue = 0;
                    for (let i = 0; i < cartItemQuantityArr.length; i++) {
                        totalCartValue += cartItemPriceArray[i] * cartItemQuantityArr[i];
                    }
                    dataCart.value = totalCartValue;
                    dataCart.currency = 'INR';
                }
            })
        })
    }

    if (cartReducer && cartReducer.cartVar && cartReducer.cartVar.length > 0) {
        cartReducer.cartVar.forEach(vrn => {
            products.push(createProductDataLayerObj(vrn, btnFlow, filter))
            items.push(createItemsDataLayerObj(vrn, btnFlow, filter))
            let name = vrn.sv_nm
            let id = vrn.sv_id
            let price = vrn.totOffPr
            itemNames.push(name)
            ids.push(id)
            prices.push(price)
            cartItemIdsArr.push(vrn.sv_id)
            cartItemNameArr.push(vrn.sv_nm)
            cartItemPriceArray.push(vrn.totOffPr / vrn.qty)
            cartItemMrpArr.push(vrn.totMrp / vrn.qty)
            cartItemQuantityArr.push(vrn.qty)
            cartItemSecCatArr.push(vrn.secondary_category)
            if (cartItemIdsArr.length) { dataCart.cartItemIds = cartItemIdsArr.join(','); }
            if (cartItemNameArr.length) { dataCart.cartItemsNames = cartItemNameArr.join(','); }
            if (cartItemPriceArray.length) { dataCart.cartItemsPrice = cartItemPriceArray.join(','); }
            if (cartItemMrpArr.length) { dataCart.cartItemsMrp = cartItemMrpArr.join(','); }
            if (cartItemQuantityArr.length) { dataCart.cartItemsQuantity = cartItemQuantityArr.join(','); }
            if (cartItemSecCatArr.length) { dataCart.cartItemsSecondaryCatogories = cartItemSecCatArr.join(','); }
            if (cartItemPriceArray.length && cartItemQuantityArr.length && cartItemPriceArray.length === cartItemQuantityArr.length) {
                let totalCartValue = 0;
                for (let i = 0; i < cartItemQuantityArr.length; i++) {
                    totalCartValue += cartItemPriceArray[i] * cartItemQuantityArr[i];
                }
                dataCart.value = totalCartValue;
                dataCart.currency = 'INR';
            }
        })
    }

    if (products.length || items.length) {
        if (step === 1) {
            window.dataLayer.push(getDataLayerObj(products, items, step));
        } else if (step === 2) {
            window.dataLayer.push(getDataLayerObj(products, items, step));
        } else {
            if (expressCheckout) {
                window.dataLayer.push(getDataLayerObj(products, items, 1));
                window.dataLayer.push(getDataLayerObj(products, items, 2));
            }
            else {
                window.dataLayer.push(getDataLayerObj(products, items, step));
            }
        }
    }
    let clickStream = {
        eventName: "PAY_NOW_CLICK",
        widgetName: "Pay Now Click",
        price: prices.toString(),
        itemName: itemNames.toString(),
        id: ids.toString(),
        screenName: window.pageType
    }
    HK.triggerComponentClickEvent(clickStream)
    HK.triggerComponentViewEvent(clickStream)
}

const getDataLayerObj = (products, items, step) => {
    const option = getStepTitle(step);
    let ecomDataLayer = {
        'event': 'LS_MB_checkout_Step' + step,
        'ecommerce': {
            'checkout': {
                'actionField': { 'step': step, 'option': option },
                'products': products,
                'items': items,
            }
        },
    }
    return ecomDataLayer;
}

const getStepTitle = (step) => {
    switch (step) {
        case 1:
            return 'Proceed to Buy';
        case 2:
            return 'Select Address';
        case 3:
            return 'Securely Pay';
        default:
            return ''
    }
}

const createProductDataLayerObj = (obj, btnFlow, filter) => {
    return {
        'name': obj.sv_nm || "",
        'id': obj.sv_id || "",
        'price': obj.totOffPr || 0,
        'brand': obj.brand || "",
        'category': obj.catName || "",
        'quantity': obj.qty || 0,
        'variant': '',
        'dimension6': window.pageType || "Cart Page",
        'dimension7': obj.orderable ? 'Out_Of_Stock' : 'In_Stock',
        'dimension8': obj.discount || 0,
        'dimension9': filter || '',
        'dimension10': btnFlow || "",
    }
}
const createItemsDataLayerObj = (obj, btnFlow, filter) => {
    return {
        'item_name': obj.sv_nm || "",
        'item_id': obj.sv_id || "",
        'price': obj.totOffPr || 0,
        'item_brand': obj.brand || "",
        'item_category': obj.catName || "",
        'quantity': obj.qty || 0,
        'item_variant': '',
        'dimension6': window.pageType || "Cart Page",
        'dimension7': obj.orderable ? 'Out_Of_Stock' : 'In_Stock',
        'dimension8': obj.discount || 0,
        'dimension9': filter || '',
        'dimension10': btnFlow || "",
    }
}

