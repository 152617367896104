import React from 'react';
import HK from 'factory/modules/HK'
import styles from '../css/pendingOrder.module.scss';
import ModalTemplate from 'utility/ModalTemplate';

const PaymentPendingOrderConfirmationPopup = ({ confirmationByUserForPendingPayment }) => {

    const orderConfImg = HK.getImage("/pendingOrder/orderConfirm.svg")
    const is_Mobile = HK.isMobile();

    const renderPopup = () => {
        return (
            <div className={`${styles['orderConf-popup-mobile']}`}>
                <div className={`${styles['orderConf-popup-inner']}`}>
                    <img src={orderConfImg} alt='red circle img' className={`${styles['img-container']}`} />
                    <p className={`${styles['conf-text']}`}> You have a pending order, do you wish to proceed with this order?</p>
                    <button className={`${styles['yes-btn']}`} onClick={() => confirmationByUserForPendingPayment('yes')}>Yes</button>
                    <button className={`${styles['no-btn']}`} onClick={() => confirmationByUserForPendingPayment('no')}>No</button>
                </div>
            </div>
        )
    }

    return (
        is_Mobile ?
            <ModalTemplate classWidthChange={'common-modal-body-props-classWidthChange-mweb'} isCenter={true} body={renderPopup()}></ModalTemplate>
            : <ModalTemplate isCenter={true} classWidthChange={'common-modal-body-props-classWidthChange'} changeWidth={true} body={renderPopup()}></ModalTemplate>
    )
}

export default PaymentPendingOrderConfirmationPopup;